import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';

const All_time_energythroughput = ({ value }) => {
  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="title" variant="body-l" as="span">
        All Time Energy Throughput
      </ManText>
      <PopoverComponent id= 'All_time_energythroughput' content='This KPI displays the total energy throughput (all time) of the vehicle.

If more than one vehicle is selected average values are displayed.' />

      <ManText variant={"heading-s"} as={"span"}>{value}</ManText>

    </ManCard>
  );
};

All_time_energythroughput.propTypes = {
  value: PropTypes.string.isRequired,
};

export default All_time_energythroughput;
import { useEffect, useState } from "react";

// Custom hook to fetch dropdown data
const useDynamicFiltering = () => {
  const [dropdownInfo, setDropdownInfo] = useState(null);

  // State to store dropdown information

  // console.log('selected_value_dynamic',selected_value);


  // Async function to fetch data from the server
  const dynamicFiltering = async ({selected_value}) => {

    try {
      let baseURL;
      if (window.location.protocol === 'https:') {
          baseURL = 'https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws';
      } else {
          baseURL = 'http://localhost:8080';
      }



      const response = await fetch(`${baseURL}/dynamic-filtering`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Convert selectedValues to JSON and include it in the request body
        body: JSON.stringify({selected_value}),
      });
      // Check if the response is not okay and throw an error if needed
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const jsonData = await response.json();

      console.log('jsondata_dynamicfiltering',jsonData)


      // Set the dropdown information in the state
      return jsonData;
    } catch (error) {
      // Log any errors that occur during the fetching process
      console.error("Error fetching data:", error);
    }
  };
  // Use useEffect to call the dynamicFiltering function when the component mounts and whenever selected_value changes
  // useEffect(() => {
  //   if (selected_value!=null){
  //   dynamicFiltering();
  //   }
  // }, [selected_value]);

  return {dynamicFiltering,dropdownInfo};
};

// Export the custom hook for use in other components
export default useDynamicFiltering;

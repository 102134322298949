import { useGraphContext } from "../../GraphContext";
import pako from 'pako';
const useHandleQuery = () => {
  const { graphData,updateGraphData } = useGraphContext();

  const handleQuery = async ({ selectedValues }) => {
    try {
      // Log the selected values for debugging purposes
      let baseURL;
      if (window.location.protocol === 'https:') {
          baseURL = 'https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws';
      } else {
          baseURL = 'http://localhost:8080';
      }


      // Make a POST request to the specified endpoint
      const response = await fetch(`${baseURL}/selected-option`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedValues }),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      // Check the Content-Encoding header of the response
      const contentEncoding = response.headers.get('Content-Encoding');
      
      let result;
      if (contentEncoding === 'gzip') {
        // If the response is gzip-compressed, decompress it
        const compressedData = await response.arrayBuffer();
        const decompressedData = pako.ungzip(new Uint8Array(compressedData), { to: 'string' });
        result = JSON.parse(decompressedData);
      } else {
        // If the response isn't gzip-compressed, parse it as JSON directly
        result = await response.json();
      }
      
      updateGraphData(result);

      console.log('ty3vdev',graphData)

      // Log the result for debugging purposes
      
    } catch (error) {
      // Log any errors that occur during the process
      console.error('Error:', error.message);
    }
  };

  // console.log('graph_data',graphData);

  return handleQuery;
};

export default useHandleQuery;

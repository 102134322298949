
import { ManText, ManDropdownMenu,ManSelect,ManSelectItem } from "@craft-design-system/core-components-react";
import info from "../assets/images/info.svg";


const MAN_info = () => {
    return (
        <div className="MAN_info_banner" style={{ backgroundColor: "rgba(255, 176, 176, 1)", fontSize: '18px',
        height: '35px',padding:'12px',gap:'8px',display:'flex' }}>
            <div className="MAN_info">
            <img className="info-logo" src={info} style={{height:'18px',width:'18px'}}/>
            <ManText className="MAN_info_text" variant={"heading-s"} as={"span"}>
            Confidential information. Usage for MAN internal purposes only.
            </ManText>

            </div>

        </div>
    );
};

export default MAN_info;
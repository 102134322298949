import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import PopoverComponent from '../popover';

const TotalMileageCard = ({ value }) => {
  return (
    <ManCard className='card' appearance="soft" outline="false">
      <ManText className="title" variant="body-l" as="span">
        Total Mileage
      </ManText>
      <PopoverComponent id= 'total_mileage' content='This KPI displays the total driven distance of the vehicle since start of data acquisition.

If more than one vehicle is selected the total driven distance of all selected vehicles is displayed (sum).' />

      <ManText  variant={"heading-s"} as={"span"}>{value} km</ManText>
      {/* <h2 className='total_mileage_value'>{value} Km</h2> */}
    </ManCard>
  );
};

TotalMileageCard.propTypes = {
  value: PropTypes.number.isRequired,
};

export default TotalMileageCard;
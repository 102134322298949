import { useEffect, useState } from "react";

const useGraphRadio = () => {

  const [radio_data,set_radio_data] = useState(null);

  const RadioQuery = async ({vins_short,radio_value,columnname }) => {
    console.log('vin_shortecec',vins_short,radio_value,columnname);
    try {
      let baseURL;
      if (window.location.protocol === 'https:') {
          baseURL = 'https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws';
      } else {
          baseURL = 'http://localhost:8080';
      }


      // const response = await fetch(`${baseURL}/dropdown`);
    // Make a GET request to the dropdown endpoint
    const response = await fetch(`${baseURL}/radio-dropdown-graph`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // Convert selectedValues to JSON and include it in the request body
      body: JSON.stringify({vins_short,radio_value,columnname}),
    });
    // Check if the response is not okay and throw an error if needed
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const jsonData = await response.json();

    console.log('jsondata_dynamicfiltering',jsonData)


    // Set the dropdown information in the state
    return {jsonData};

  } catch (error) {
    // Log any errors that occur during the fetching process
    console.error("Error fetching data:", error);
  }
  };
  // console.log('graph_dataefef',radio_data);

  return RadioQuery;
};

export default useGraphRadio;

import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import PopoverComponent from '../popover';

const Start_of_data_acquisition = ({ value }) => {
  return (
    <ManCard className='card' appearance="soft" outline="false">
      <ManText className="title" variant="body-l" as="span">
        Start of Data Acquisition
      </ManText>
      <PopoverComponent id= 'data_acquisition' content='This KPI shows the earliest time stamp at which telematics data is available for the selected vehicle. This time stamp is the baseline for different KPIs on the dashboard (e.g. Total Driven Distance, State of Health).

No value is displayed if more than one vehicle is selected.' />

      {/* <h2 className='start_of_data_acquisition'>{value}</h2> */}
      <ManText variant={"heading-s"} as={"span"}>{value}</ManText>

    </ManCard>
  );
};

Start_of_data_acquisition.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Start_of_data_acquisition;
import React from 'react';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Spinner } from 'react-bootstrap'; // Import Spinner from react-bootstrap
import Mainscreen from './screens/main_screen';
import Shimmer_graph from './components/shimmer_graph';


function App() {
  const { accounts, inProgress } = useMsal();
  const account = accounts[0] || {};
  const isAuthenticated = accounts.length > 0;

  if (inProgress === 'login') {
    // Display a spinner if the authentication process is in progress
    return <Shimmer_graph />;
  }
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
      <div>
        {isAuthenticated ? (
          <Mainscreen />
        ) : (
          <h1>Please sign in</h1>
        )}
      </div>
    </MsalAuthenticationTemplate>
  );
}

export default App;
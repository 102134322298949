import { LogLevel } from '@azure/msal-browser';
import { Configuration } from "@azure/msal-browser";


const msalConfig: Configuration = {
    auth: {
      clientId: "922484da-0f99-4351-aa37-dbb21a59caa3",
      authority: "https://login.microsoftonline.com/ccc63daa-d84b-4abd-98fd-4eeb839de273",
      redirectUri: "https://simbaanalytics-dev.rio.cloud/",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };
  
export default msalConfig;



import { ManText, ManDropdownMenu,ManSelect,ManSelectItem } from "@craft-design-system/core-components-react";


const Shimmer = () => {
    return (
            <div className="sidebar">

<div className="sidebar-title">
            <ManText className="filter-title" variant={"heading-xl"} as={"span"} >
            Filters
            </ManText>
        </div>
        <ManText   className="filter-text" variant={"heading-xl"} as={"span"} >
            Loading...
        </ManText>
        

        </div>
    )
}

export default Shimmer;
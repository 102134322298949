import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';

const Installation_energy = ({ value }) => {
  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="title" variant="body-l" as="span">
        Installation Energy
      </ManText>
      <PopoverComponent id= 'installation_energy' content='This KPI states the installed capacity on the vehicle. The value is taken from the vehicle configuration.

No value is displayed if more than one vehicle is selected.' />

      <ManText variant={"heading-s"} as={"span"}>{value}</ManText>

    </ManCard>
  );
};

Installation_energy.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Installation_energy;
import { ManText, ManDropdownMenu,ManSelect,ManSelectItem,ManSpinner } from "@craft-design-system/core-components-react";


const Shimmer_graph_man = () => {
    return (
            <div className="card-graph-shimmer">
                <ManSpinner size="l" variant="standard" label="We're preparing your content... Please hold"></ManSpinner>
        

        </div>
    )
}

export default Shimmer_graph_man;
import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import PopoverComponent from '../popover';

const Yearly_mileage = ({ value }) => {
  let graphData1 = "";

  try {
    graphData1 = JSON.parse(value);
  } catch (error) {
    console.error("Error parsing JSON", error);
  }

  return (
    <div>

    <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
     Yearly Mileage
      </ManText>
      <PopoverComponent id= 'Yearly_mil' content='This KPI displays the yearly driven distance of the vehicle. 

If more than one vehicle is selected, both the sum and the average value of yearly mileage are displayed.' />

      </div>
    
    <Plot
      data={graphData1.data}
      layout={graphData1.layout}
    />
    </div>
  );
};

Yearly_mileage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Yearly_mileage;
// PopoverComponent.js
import React from 'react';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import ifav from '../assets/images/ifav.png';


const PopoverComponent = ({id,content }) => {
  const popover = (
    <Popover id={id}>
      <Popover.Body>{content}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
        <img className="man-logovv1" src={ifav}/>
    </OverlayTrigger>
  );
};

export default PopoverComponent;
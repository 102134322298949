import  { useState, useEffect } from 'react';
import { ManText, ManSelect, ManSelectItem,ManInputText} from "@craft-design-system/core-components-react";
import Shimmer from "./Shimmer";
import useDropdownData from '../utils/dropdown_data_loading/useDropdownData';
import useHandleQuery from "../utils/dropdown_data_loading/useHandleQuery";
import useDynamicFiltering from '../utils/dropdown_data_loading/useDynamicFiltering';
import { Collapse } from 'reactstrap'; // or react-bootstrap
import filters from '../assets/images/filters.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import MAN_Update from'../assets/images/MAN_Update.svg';
import reload from '../assets/images/reload.png';
// import filter from '../assets/images/filter.jpg';
import submit from '../assets/images/submit.png';
import filter from '../assets/images/filter.png'
import 'react-tooltip/dist/react-tooltip.css'

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { ManHelperText } from '@craft-design-system/core-components-react';

import { Tooltip as ReactTooltip } from 'react-tooltip'

import YourComponent from './newComponent';
import TabComponent_filters from './tabs_filter';



const Sidebar = () => {

  const [selectedValues, setSelectedValues] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownData, setDropdownStates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showTextData, setShowTextData] = useState(false);

  // handlegraphclick = YourComponent();

  const { dynamicFiltering, dropdownInfo } = useDynamicFiltering();
  let {dropdowndata,fetchData,isRefreshing} = useDropdownData();
  console.log('dropdownNames',dropdowndata); 
  const handleQuery = useHandleQuery();

  
function handleHelpClick() {
    window.location.href = `mailto:support@example.com?subject=Need Help&body=Please describe your problem here.`;
}

  const handleSelectChange = (selectedOptions, columnName) => {
    setSelectedValues(prevValues => {
      const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
      return {
        ...prevValues,
        [columnName]: newValues,
      };
    });
    setIsInitialLoad(false);
  };
  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'red',  // Change this to your desired color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'grey' : state.isFocused ? '#EAEDF3' : null,
      color: state.isSelected ? 'white' : 'black',

    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '1px',
      borderWidth: '1px',
      borderColor: '#5B6F85',
      cursor: 'pointer',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        // display: 'inline-block',
        // margin: '2px',
      };
    },
    valueContainer: (styles, { data }) => {
      return {
        ...styles,
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
      };
    },
  };


    const popover = (
      <Popover id="popover-basic">
        <Popover.Body>
          Reset all filters
        </Popover.Body>
      </Popover>
    );

    const popover_filter = (
      <Popover id="popover-basic">
        <Popover.Body>
        Update filter options

        </Popover.Body>
      </Popover>
    );

  useEffect(() => {
    setDropdownStates(dropdowndata);
    setSelectedValues(null);
  }, [dropdowndata]);

  const handleButtonClick = async () => {
    setIsLoading(true);
    setShowText(true);

    
    console.log('selectedValues',selectedValues);
    if (selectedValues === null) {
      setShowTextData('Please select at least one filter');
      setIsLoading(false);
      setTimeout(() => setShowText(false), 4000);  // Hide text after 1 second

    } else {
      setShowTextData('Updating filters...');
      const newDropdownInfo = await(dynamicFiltering({ selected_value: selectedValues }));
      console.log(newDropdownInfo);
      setDropdownStates(newDropdownInfo);
    
      setIsLoading(false);
      setShowTextData('Filters Updated!!');
      setTimeout(() => setShowText(false), 4000);  // Hide text after 1 second
    }


  };


  if (!dropdownData || isRefreshing ) return <Shimmer />;

  return (
    
    <div className='sidebar'>
      <div className="sidebar-title">
        <ManText  className="filter-title" variant={"heading-l"} as={"span"}>
          Filters
        </ManText>

        <OverlayTrigger trigger="hover" placement="left" overlay={popover_filter}>


          {isLoading ? <button className='update_img' onClick={handleHelpClick}>
      <img src={filter} alt="button image" className='submit_image'/>

      </button> : <button className='update_img' onClick={handleButtonClick}>
      <img src={filter} alt="button image" className='submit_image'/>

      </button>}
      </OverlayTrigger>


      


      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>

        <button className='update_img' onClick={fetchData}>
        <img src={reload} alt="button image" className='reload_image'/>

        </button>
        </OverlayTrigger>

        
        <YourComponent selected_value={selectedValues} />


      </div>

      {showText && <div style={{color:'rgb(48,60,73)',fontFamily:'MAN EUROPE_Bold',display:'flex',justifyContent:'center'}}>
      <ManHelperText
        style={{fontSize: '14px'}}
        content={showTextData}
        variant="error"
        show={true}
        ></ManHelperText>

        </div>}


      <TabComponent_filters initial_tab={'Bus'} />

      <div className="dropdown-section">
      {dropdownData && 
          Object.entries(dropdownData).slice(0,5).map(([columnName, values]) => {
            const options = values.map(value => ({ label: value, value }));
            return (
              <div className="sidebar-filters" key={columnName}>
                <ManText className="filter-text" variant={"heading-s"} as={"span"}>
                  {columnName}
                </ManText>

                <Select
                  isMulti
                  name={columnName}
                  options={options}

                  styles={customStyles}

                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => handleSelectChange(event, columnName)}                />
              </div>
            );
          })
        }
<Collapse isOpen={isOpen}>
{dropdownData && 
          Object.entries(dropdownData).slice(5  ).map(([columnName, values]) => {
            const options = values.map(value => ({ label: value, value }));
            return (
              <div className="sidebar-filters" key={columnName}>
                <ManText className="filter-text" variant={"heading-s"} as={"span"}>
                  {columnName}
                </ManText>

                <Select
                  isMulti
                  name={columnName}
                  options={options}

                  styles={customStyles}

                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => handleSelectChange(event, columnName)}                />
              </div>
            );
          })
        }
</Collapse>
<ManText variant={"heading-s"} as={"span"}>
    <button className='filters_button' onClick={() => setIsOpen(!isOpen)}>
    {isOpen ? 'Less filters' : 'More filters'}
    <img src={filters} alt="button image" className='filter_image' />
    </button>
        </ManText>


  
</div>
    </div>
  );
};

export default Sidebar;

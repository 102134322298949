import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';

const No_of_packs = ({ value }) => {
  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="title" variant="body-l" as="span">
        Number of packs
      </ManText>
      <PopoverComponent id= 'no_of_packs' content='This KPI states the installed number of battery packs on the vehicle. The value is taken from the vehicle configuration.

No value is displayed if more than one vehicle is selected.' />

      <ManText variant={"heading-s"} as={"span"}>{value}</ManText>

    </ManCard>
  );
};

No_of_packs.propTypes = {
  value: PropTypes.string.isRequired,
};

export default No_of_packs;
import React from 'react';
import { useGraphContext } from '../GraphContext';
import TabComponent from './tabs_graphs';
import Shimmer_graph from './shimmer_graph';
import  Shimmer_graph_man from './MAN_info_graphs'
import Donut from './Donut'


const Graphs = () => {
  const { graphData } = useGraphContext();

  console.log('graphData',graphData)

  if (!graphData) {
    return <Shimmer_graph_man />;
  }


  const {donut_chart}=graphData;

  

  return (
    <div className='Graphs'>
      {graphData ?
      <>
      <div>
      <Donut value={{ donut_chart}} />
      <TabComponent initial_tab='Ride Analysis'/>
      </div>
      </> 

      : <Shimmer_graph_man />}
    </div>
  );
}

export default Graphs;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Software_stage = ({ value }) => {
  const [software_stage, set_software_stage] = useState(null);

//   console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      This KPI states the installed battery software stage on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per battery software stage is displayed.
      </Popover.Body>
    </Popover>
  );
//   console.log('test',software_stage)






  useEffect(() => {
    try {
        set_software_stage(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Battery Software Stage
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>



      {software_stage && <Plot data={software_stage.data} layout={software_stage.layout} />}    
      </ManCard>
  );
};

Software_stage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Software_stage;
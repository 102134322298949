import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const MAN_part_no = ({ value }) => {
  const [man_part_no, set_man_part_no] = useState(null);

//   console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      This KPI states the installed MAN part number of the battery on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per battery MAN part number is displayed.
      </Popover.Body>
    </Popover>
  );
//   console.log('test',man_part_no)






  useEffect(() => {
    try {
        set_man_part_no(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      MAN Part Number Battery
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>



      {man_part_no && <Plot data={man_part_no.data} layout={man_part_no.layout} />}    
      </ManCard>
  );
};

MAN_part_no.propTypes = {
  value: PropTypes.string.isRequired,
};

export default MAN_part_no;
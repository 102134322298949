// GraphContext.js
import { createContext, useContext, useState } from 'react';

const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
  const [graphData, setGraphData] = useState();
  const clearGraphData = () => setGraphData(null);


  const updateGraphData = (newGraphData) => {
    setGraphData(newGraphData);
  };

  return (
    <GraphContext.Provider value={{ graphData, updateGraphData,clearGraphData }}>
      {children}
    </GraphContext.Provider>
  );
};

export const useGraphContext = () => {
  return useContext(GraphContext);
};

// Footer.js
import React from 'react';
import { ManCard,ManText } from '@craft-design-system/core-components-react';




function handleHelpClick() {
    window.location.href = `mailto:d76d4c5f.m365.man@emea.teams.ms?subject=Need Help&body=Please describe your problem here.`;
  }
  
const Footer = () => {
  return (
    <div className='footer'>
              <button style={{background:'white',border:'0px'}} onClick={handleHelpClick}>            
        <ManText className="logout" variant={"heading-s"} as={"span"} >
            Need help?
            </ManText></button>
    </div>
  );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const A_cyc_val = ({ value }) => {
  const [a_cyc_val, set_a_cyc_val] = useState(null);

//   console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The average cyclic aging factor (a_cyc_avg) is the average value over all matrix elements. 

Unit of average cyclic aging factor: %-SoH-Loss / energy throughput (kWh)

If more than one vehicle is selected the distribution of a_cyc_avg is displayed.
      </Popover.Body>
    </Popover>
  );
//   console.log('test',a_cyc_val)






  useEffect(() => {
    try {
        set_a_cyc_val(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
        style={{}}>
        Cyclic Aging Factor
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
        <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {a_cyc_val && a_cyc_val.data && a_cyc_val.data.length > 0 
        ? <Plot data={a_cyc_val.data} layout={a_cyc_val.layout} />
        : <ManText className="title" variant="body-l" as="span">
            KPI will be available soon
          </ManText>
      }
    </ManCard>
  );
};

A_cyc_val.propTypes = {
  value: PropTypes.string.isRequired,
};

export default A_cyc_val;
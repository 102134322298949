import React, { useState } from 'react';
import { ManText, ManDropdownMenu,ManSelect,ManSelectItem } from "@craft-design-system/core-components-react";

const TabComponent_filters = ({initial_tab}) => {
  const [selectedTab, setSelectedTab] = useState(initial_tab);
  
  const isDisabled = selectedTab === 'Bus'; // Change this condition based on your needs

  const tabStyle = {
    padding: '10px, 0px, 10px, 0px',
    cursor: 'pointer',
    fontWeight: 'bold',
    borderBottom: ' #d3d3d3 solid 1px',
    marginRight: '5px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    color:'black',
    background: 'white'
  };

  const selectedTabStyle = {
    ...tabStyle,
    borderBottom: 'red solid 2px',
    background: 'white',
    color: '#E40045'
  };

  const disabledTabStyle = {
    ...tabStyle,
    color: '#d3d3d3',
    cursor: 'not-allowed',
    pointerEvents: 'none'

  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-around' ,backgroundColor: 'white'}}>
        <span 
          style={selectedTab === 'Bus' ? selectedTabStyle : tabStyle} 
          onClick={() => !isDisabled && setSelectedTab('Bus')}
        >
          <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Bus
            </ManText>
        </span>
        <span 
          style={selectedTab === 'Truck' ? selectedTabStyle : isDisabled ? disabledTabStyle : tabStyle} 
          onClick={() => setSelectedTab('Truck')}
        >
        <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Truck
        </ManText>
        </span>
      </div>

      {selectedTab === 'Bus' && <div></div>}
      {selectedTab === 'Truck' && <div></div>}
    </div>
  );
};

export default TabComponent_filters;
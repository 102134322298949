import { ManText, ManDropdownMenu,ManSelect,ManSelectItem,ManSpinner } from "@craft-design-system/core-components-react";
import ReactLoading from 'react-loading';

const Shimmer_graph = () => {
    return (
            <div className="card-info-shimmer">
                <ManSpinner size="l" variant="standard" label="We're preparing your content... Please hold"></ManSpinner>

        

        </div>
    )
}

export default Shimmer_graph;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import PopoverComponent from '../popover';

const AverageSpeed= ({ value }) => {
  const [average_speed, set_average_speed] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Average Speed'}).then(data => {
        set_average_speed(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    console.log('testing23456', average_speed);
  }, [average_speed]);

  useEffect(() => {
    try {
      set_average_speed(JSON.parse(value.average_speed));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <div >
    <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Average Speed
      </ManText>
      <PopoverComponent id= 'Average_speed' content='This KPI displays the daily average speed of the vehicle in the given time period.

If more than one vehicle is selected average values are displayed.' />

      <ManRadioButtonGroup 
        name="Average Speed"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Average Speed" label="Daily" value="Daily"  />
        <ManRadioButton name="Average Speed" label="Monthly" value="Monthly" />
        <ManRadioButton name="Average Speed" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>
      </div>
      {isLoading ? <Shimmer_graph /> : average_speed && <Plot data={average_speed.data} layout={average_speed.layout} />}    
      </div>
  );
};

AverageSpeed.propTypes = {
  value: PropTypes.string.isRequired,
};
 
export default AverageSpeed;